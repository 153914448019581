<template>
    <div>
        <div class="sidebar-section">
            <ul class="media-list media-list-linked my-2">
                <div v-if="1===1">
                    <li v-for="(user, index) in users" :key="user.id">
                        <a :href="single_chat_url+'/'+user.id" class="media">
                            <div class="mr-3">
                                <img v-if="user.avatar" :src="baseUrl+ user.avatar"  class="rounded-circle" width="40" height="40" alt="">
                                <img v-else-if="user.avatar_url" :src="baseUrl+ user.avatar_url"  class="rounded-circle" width="40" height="40" alt="">
                                <img v-else :src="baseUrl +'global_assets/images/default_user_pic.png'"  class="rounded-circle" width="40" height="40" alt="">
                            </div>
                            <div class="media-body">
                                <div class="media-title font-weight-semibold">{{ user.first_name }} {{ user.last_name }}</div>
                                <span class="text-muted font-size-sm" v-if="user.last_message" :id="'last_message'+index">{{ user.last_message.substring(0,20)+"..."}}</span>
                            </div>
                            <div class="align-self-center ml-3">
                                <span v-if="user.active_status.status == 1" class="badge badge-mark bg-success border-success" ></span>
                                <span v-else-if="user.active_status.status == 0" class="badge badge-mark bg-dark border-dark" ></span>
                                <span v-else-if="user.active_status.status == 3" class="badge badge-mark bg-danger border-danger" ></span>
                                <span v-else class="badge badge-mark bg-warning border-warning" ></span>
                            </div>
                        </a>
                    </li>
                </div>
                <div v-else>
                    <li class="media bg-light font-weight-semibold py-2">{{__('no')}} {{__('conversition')}} {{__('found')}}!</li>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
    components: {
        vSelect,
    },
    props:{
        search_url:{
            type: String,
            required: true,
        },
        single_chat_url:{
            type: String,
            required: true,
        },
        chat_block_url:{
            type: String,
            required: true,
        },
        create_group_url:{
            type: String,
            required: true,
        },
        group_chat_show:{
            type: String,
            required: true,
        },
        users:{
            required: true,
        },
        all_users:{
            required: true,
        },
        groups:{
            required: true,
        },
        can_create_group:{
            default : true,
        },
        asset_type:{
          default : ''
        }
    },
    data() {
        return {
          baseUrl: Laravel.baseUrl,
          tags : []
        }
    },
    created() {
    },

    mounted() {
        this.tags = this.all_users.map(function(value) {
            if (value.last_name){
                return value.first_name;
            }else{
                return value.first_name;
            }
        });
    },

    methods: {
        sendMessage() {
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }
            let formData = new FormData();
            if(this.replying_to){
                formData.append('reply', this.replying_to);
            }
            formData.append('file_attach', this.file);
            formData.append('message', this.newMessage);
            formData.append('from_id', this.from_user.id);
            formData.append('to_id', this.to_user.id);

            axios.post(this.send_message_url, formData, config).then((response) => {
                this.replying = false;
                this.messagePush(response.data.message)
                this.reset();
                this.cleanReply();
                this.$forceUpdate()
                this.closePreview()
            }).catch((error) => {
                console.log(error);
            });
            this.newMessage = '';
            $("#inputbox").css('height','40px');

        },
        sendTypingEvent() {
            Echo.private('single-chat.'+this.to_user.id)
                .whisper('single-typing',{
                    name: 'kjk'
                });
        },

        openUserProfileModal(id){
            $('#'+id).modal('show');
        }

    }
}
</script>

<style scoped>

  .chat_flow_list_inner ul{
      list-style: none;
  }

</style>
