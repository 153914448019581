<template>
    <div>
        <div class="card">
            <div class="card-header bg-dark text-white header-elements-inline py-0">
                <h6 class="card-title py-2">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <img v-if="this.to_user.avatar" :src="baseUrl+ this.to_user.avatar" class="rounded-circle" width="32" height="32" alt="">
                            <img v-else-if="this.to_user.avatar_url" :src="baseUrl+ this.to_user.avatar_url" class="rounded-circle" width="32" height="32" alt="">
                            <img v-else :src="baseUrl +'global_assets/images/default_user_pic.png'" class="rounded-circle" width="32" height="32" alt="">
                            <span v-if="this.to_user.active_status.status == 1" class="badge badge-mark badge-float border-success mt-1 mr-1"></span>
                            <span v-else-if="this.to_user.active_status.status == 0" class="badge badge-mark badge-float border-dark mt-1 mr-1"></span>
                            <span v-else-if="this.to_user.active_status.status == 3" class="badge badge-mark badge-float border-danger mt-1 mr-1"></span>
                            <span v-else class="badge badge-mark badge-float border-warning mt-1 mr-1"></span>
                        </li>
                        <li class="list-inline-item">
                            {{ this.to_user.first_name }} {{ this.to_user.last_name }}
                        </li>
                    </ul>
                </h6>
                <div class="header-elements">
                    <div class="list-icons">
                        <a data-toggle="modal" data-target="#chat_search" class="list-icons-item cursor-pointer mr-2"><i class="fas fa-search"></i></a>
                        <a :href="files_url" class="list-icons-item"><i class="icon-images3"></i></a>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div v-if="search_result_bar" class="toast py-1 mb-1" style="opacity: 1; max-width: none;">
                    <div class="toast-body pt-1 pb-1">
                        <div class="float-left">
                            <p class="mb-0">{{ __('showing') }} {{ this.search_result_count === 0 ? this.search_result_count
                                    : this.search_index + 1
                            }} {{ __('of') }} {{ this.search_result_count }} {{ __('results') }}</p>
                        </div>
                        <div class="float-right">
                            <p class="mb-0">
                                <a href="#" @click="scroll_to_search_up()" class="px-1"> <span class="icon-arrow-up52 text-white"></span> </a>
                                <a href="#" @click="scroll_to_search()" class="px-1"> <span class="icon-arrow-down52 text-white"></span> </a>
                                <a href="#" @click="close_search_bar()" class="px-1"> <span class="icon-x text-white"></span> </a>
                            </p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>

                <div class="media-chat-scrollable mb-3" id="chat_container" style="max-height: 70vh; min-height: 63vh;" v-chat-scroll>
                    <ul class="media-list media-chat">
                        <li class="media w-100">
                            <p class="text-center w-100" v-show="loadable">
                                <a class="badge badge-primary cursor-pointer" @click="loadMore()"> {{ __('load_more') }} <span class="icon-arrow-up8"></span></a>
                            </p>
                        </li>
                        <div :id="'target' + index" v-for="(conversation, index) in this.conversations" :key="conversation.id">
                            <!--Chat other side-->
                            <div v-if="parseInt(conversation.from_id) != from_user.id && conversation.deleted_by_to == '0'" class="chat_single" style="overflow: unset">
                                <li class="media">
                                    <div class="mr-3">
                                        <a v-if="to_user.avatar" href="#"><img :src="'/' + to_user.avatar" class="rounded-circle" width="40" height="40" alt=""></a>
                                        <a v-else-if="to_user.avatar_url" href="#"><img :src="baseUrl + to_user.avatar_url" class="rounded-circle" width="40" height="40" alt=""></a>
                                        <a v-else href="#"><img :src="baseUrl + 'global_assets/images/default_user_pic.png'" class="rounded-circle" width="40" height="40" alt=""></a>
                                    </div>
                                    <div class="media-body">
                                        <ul class="list-inline list-inline-condensed mb-0 chat-item-base">
											<li class="list-inline-item">
												<div class="audio-padding media-chat-item" v-if="conversation.message_type == 4">
                                                    <audio :src="baseUrl + conversation.file_name" controls></audio>
                                                </div>
                                                <div v-else-if="conversation.message_type == 5" class="media-chat-item p-3 w-100">
                                                    <video class="w-100 border-radius-25" controls>
                                                        <source :src="baseUrl + conversation.file_name" type="video/mp4">
                                                    </video>
                                                </div>
                                                <div v-else-if="conversation.message_type == 0" class="media-chat-item">
                                                    <span :id="'text' + index" class="textmsg" v-html="url_maker(conversation.message)"></span>
                                                    <div class="font-size-sm text-muted mt-2" v-if="conversation.reply">
                                                        <div class="toast bg-primary" v-if="conversation.reply.message_type == 0" style="opacity: 1; max-width: none;">
                                                            <div class="toast-body p-1">
                                                                {{ conversation.reply.message }}
                                                            </div>
                                                        </div>
                                                        <div class="toast-body bg-primary text-white" v-else>{{ __('attachment') }}</div>
                                                    </div>
                                                    <div v-if="conversation.forward_from">
                                                        <div class="toast bg-light text-body" v-if="conversation.forward_from.message_type == 0" style="opacity: 1; max-width: none;">
                                                            <div class="toast-body p-1"><u>{{ __('forwarded_message') }}</u> : {{ conversation.forward_from.message }} </div>
                                                        </div>
                                                        <div class="reply_p audio-padding" v-if="conversation.forward_from.message_type == 4">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <audio :src="baseUrl + conversation.forward_from.file_name" controls></audio>
                                                        </div>
                                                        <div v-else-if="conversation.forward_from.message_type == 5" class="reply_p p-3 w-100">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <video class="w-100 border-radius-25" controls>
                                                                <source :src="baseUrl + conversation.forward_from.file_name" type="video/mp4">
                                                            </video>
                                                        </div>
                                                        <div class="reply_p p-3" v-else-if="conversation.forward_from.message_type == 1">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <img class="cursor-pointer" @click="imageViewLargeScreen(baseUrl + conversation.forward_from.file_name)" style="max-width: 300px;"
                                                                v-if="conversation.forward_from.file_name" :src="baseUrl + conversation.forward_from.file_name" alt="">
                                                            <img class="" v-else :src="baseUrl + asset_type + '/chat/images/msg_img.png'" alt="">
                                                        </div>
                                                        <p class="reply_p" v-else-if="conversation.message_type == 2 || conversation.message_type == 3">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <u><a style="color: white;" :href="baseUrl + 'chat/file/download/' + conversation.forward_from.id">{{ conversation.forward_from.original_file_name }}</a></u>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="media-chat-item p-3" v-else-if="conversation.message_type == 1">
                                                    <img class="cursor-pointer" @click="imageViewLargeScreen(baseUrl + conversation.file_name)" v-if="conversation.file_name" :src="baseUrl + conversation.file_name" alt="" style="max-width: 300px;">
                                                    <img class="border-radius-25" v-else :src="baseUrl + asset_type + '/chat/images/msg_img.png'" alt="">
                                                </div>
                                                <div class="media-chat-item" v-else-if="conversation.message_type == 2 || conversation.message_type == 3">
                                                    <u>
                                                        <a :href="baseUrl + 'chat/file/download/' + conversation.id" class="">{{ conversation.original_file_name }}</a>
                                                    </u>
                                                </div>
                                                <div class="media-chat-item" v-else>{{ conversation.file_name }}</div>
											</li>
											<li class="list-inline-item chat-item-opt">
												<div class="list-icons">
                                                    <div class="dropdown d-inline-flex position-static dropup">
                                                        <a href="#" class="list-icons-item text-muted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon-menu"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-left" style="">
                                                            <a href="#" class="dropdown-item" :title="__('reply')" @click="reply(conversation)"><i class="icon-reply mr-1"></i>{{ __('reply') }}</a>
                                                            <a href="#" class="dropdown-item" :title="__('forward')" @click="forwardModalOpen(conversation)"><i class="fas fa-share-alt mr-1"></i> {{ __('forward') }}</a>
                                                            <!--a href="#" class="dropdown-item" :title="__('delete')" @click="deleteMessage(conversation)"><i class="icon-trash mr-1"></i>{{ __('delete') }}</a-->
                                                        </div>
                                                    </div>
                                                </div>
											</li>
										</ul>
                                        <div class="font-size-xs text-muted mb-1"> {{ diffHuman(conversation.created_at) }} </div>
                                    </div>
                                </li>
                            </div>
                            <!--/ End chat other side-->
                            <!--Chat my side-->
                            <div v-if="conversation.from_id == from_user.id" class="chat_single sender_chat" style="overflow: unset">
                                <li class="media media-chat-item-reverse">
                                    <div class="media-body">
                                        <ul class="list-inline list-inline-condensed mb-0 chat-item-base">
                                            <li class="list-inline-item chat-item-opt">
                                                <div class="list-icons">
                                                    <div class="dropdown d-inline-flex position-static dropup">
                                                        <a href="#" class="list-icons-item text-muted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="icon-menu"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right" style="">
                                                            <a href="#" class="dropdown-item" :title="__('reply')" @click="reply(conversation)"><i class="icon-reply mr-1"></i>{{ __('reply') }}</a>
                                                            <a href="#" class="dropdown-item" :title="__('forward')" @click="forwardModalOpen(conversation)"><i class="fas fa-share-alt mr-1"></i> {{ __('forward') }}</a>
                                                            <a href="#" class="dropdown-item" :title="__('delete')" @click="deleteMessage(conversation)"><i class="icon-trash mr-1"></i>{{ __('delete') }}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="media-chat-item audio-padding" v-if="conversation.message_type == 4">
                                                    <audio :src="baseUrl + conversation.file_name" controls></audio>
                                                </div>
                                                <div v-else-if="conversation.message_type == 5" class="p-3 w-100">
                                                    <video class="w-100 border-radius-25" controls>
                                                        <source :src="baseUrl + conversation.file_name" type="video/mp4">
                                                    </video>
                                                </div>
                                                <div class="media-chat-item" v-else-if="conversation.message_type == 0">
                                                    <p class="texttypemsg" :id="'text' + index" v-html="url_maker(conversation.message)"></p>
                                                    <div class="font-size-sm text-muted mt-2" v-if="conversation.reply">
                                                        <div class="toast bg-light text-body" v-if="conversation.reply.message_type == 0" style="opacity: 1; max-width: none;">
                                                            <div class="toast-body p-1"> {{ conversation.reply.message }} </div>
                                                        </div>
                                                        <div class="toast-body bg-light text-body" v-else>{{ __('attachment') }}</div>
                                                    </div>
                                                    <div v-if="conversation.forward_from">
                                                        <div class="toast bg-light text-body" v-if="conversation.forward_from.message_type == 0" style="opacity: 1; max-width: none;">
                                                            <div class="toast-body p-1"><u>{{ __('forwarded_message') }}</u> : {{ conversation.forward_from.message }} </div>
                                                        </div>
                                                        <div class="sender_reply_p audio-padding" v-if="conversation.forward_from.message_type == 4">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <audio :src="baseUrl + conversation.forward_from.file_name" controls></audio>
                                                        </div>
                                                        <div v-else-if="conversation.forward_from.message_type == 5"
                                                            class="sender_reply_p p-3 w-100">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <video class="w-100 border-radius-25" controls>
                                                                <source :src="baseUrl + conversation.forward_from.file_name" type="video/mp4">
                                                            </video>
                                                        </div>
                                                        <div class="sender_reply_p p-3" v-else-if="conversation.forward_from.message_type == 1">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <img class="border-radius-25 cursor-pointer" style="max-width: 300px;" @click="imageViewLargeScreen(baseUrl + conversation.forward_from.file_name)"
                                                                v-if="conversation.forward_from.file_name" :src="baseUrl + conversation.forward_from.file_name" alt="">
                                                            <img class="border-radius-25" v-else :src="baseUrl + asset_type + '/chat/images/msg_img.png'" alt="">
                                                        </div>
                                                        <p class="sender_reply_p" v-else-if="conversation.message_type == 2 || conversation.message_type == 3">
                                                            <span><u>{{ __('forwarded_message') }}</u> : </span><br>
                                                            <u>
                                                                <a :href="baseUrl + 'chat/file/download/' + conversation.forward_from.id">
                                                                    {{conversation.forward_from.original_file_name}}
                                                                </a>
                                                            </u>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="media-chat-item p-3" v-else-if="conversation.message_type == 1">
                                                    <img class="cursor-pointer" style="max-width: 300px;" @click="imageViewLargeScreen(baseUrl + conversation.file_name)"
                                                        v-if="conversation.file_name" :src="baseUrl + conversation.file_name" alt="">
                                                    <img class="border-radius-25" v-else :src="baseUrl + asset_type + '/chat/images/msg_img.png'" alt="">
                                                </div>
                                                <div class="media-chat-item" v-else-if="conversation.message_type == 2 || conversation.message_type == 3">
                                                    <u>
                                                        <a style="color: white;" :href="baseUrl + 'chat/file/download/' + conversation.id">{{ conversation.original_file_name }}</a>
                                                    </u>
                                                </div>
                                                <div class="media-chat-item" v-else>{{ conversation.file_name }}</div>
                                            </li>
                                        </ul>
                                        <div class="font-size-xs text-muted mb-1"> {{ diffHuman(conversation.created_at) }} </div>
                                    </div>

                                    <div class="ml-3">
                                        <a v-if="from_user.avatar" href="#"><img :src="'/' + from_user.avatar" class="rounded-circle" width="40" height="40" alt=""></a>
                                        <a v-else-if="from_user.avatar_url" href="#"><img
                                            :src="baseUrl + from_user.avatar_url" class="rounded-circle" width="40" height="40" alt=""></a>
                                        <a v-else href="#"><img :src="baseUrl + 'global_assets/images/default_user_pic.png'"
                                             class="rounded-circle" width="40" height="40" alt=""></a>
                                    </div>
                                </li>
                            </div>
                            <!--/ End chat my side-->
                        </div>
                    </ul>
                </div>

                <div v-if="!to_user.blocked" v-show="replying" class="toast bg-white text-body border-dark mb-1" style="opacity: 1;">
                    <div class="toast-header bg-light text-body border-bottom pt-1 pb-1 pl-2">
                        <span class="font-weight-semibold mr-auto">{{ __('replying') }}</span>
                        <button type="button" class="ml-3 close" @click="quote_close_preview">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="toast-body pt-1 pb-1 pl-2" v-text="replying_text"></div>
                </div>

                <div v-if="preview_url" class="toast bg-white text-body border-dark mb-1" style="opacity: 1;">
                    <div class="toast-header bg-light text-body border-bottom pt-1 pb-1 pl-2">
                        <span class="font-weight-semibold mr-auto">{{ __('attachment') }}</span>
                        <button type="button" class="ml-3 close" @click="sendMessage">
                            <span aria-hidden="true" class="icon-paperplane"></span>
                        </button>
                        <button type="button" class="ml-3 close" @click="closePreview">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="toast-body p-1">
                        <img :src="preview_url" alt="attachment" id="blah" height="32" class="img-fluid">
                    </div>
                </div>
                <div v-if="file_name_preview" class="toast bg-white text-body border-dark mb-1" style="opacity: 1;">
                    <div class="toast-header bg-light text-body border-bottom pt-1 pb-1 pl-2">
                        <span class="font-weight-semibold mr-auto">{{ __('attachment') }}</span>
                        <button type="button" class="ml-3 close" @click="sendMessage">
                            <span aria-hidden="true" class="icon-paperplane"></span>
                        </button>
                        <button type="button" class="ml-3 close" @click="closePreview">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="toast-body pt-1 pb-1 pl-2">
                        <div class="toast-body pt-1 pb-1 pl-2" id="ar">{{ file_name_preview }}</div>
                    </div>
                </div>

                <form v-if="!to_user.blocked" v-on:submit.prevent="sendMessage" :class="{ 'pt-100px': addPadding }">
                    <div class="chat_input_box d-flex align-items-center">
                        <div class="input-group">
                                <input type="text" class="form-control border-dark" v-model="newMessage" @keydown="sendTypingEvent" @keyup.enter.exact="sendMessage"
                                 id="inputbox" :placeholder="__('type_message') + '...'">
                                <span class="input-group-append">
                                    <button v-show="can_file_upload" class="btn btn-dark btn-icon btn-file">
                                        <i class="icon-file-plus"></i>
                                        <input type="file" @change="onFileChange" id="imgInp" ref="file"
                                        v-on:change="onChangeFileUpload()"
                                        accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.mp4,.3gp,.webm">
                                    </button>
                                </span>
                                <!--span class="input-group-append">
                                    <button class="btn btn-light btn-icon" type="button" @click="toggleRecording">
                                        <i :class="{ 'microphone-red': record_status }" class="icon-mic2"></i>
                                    </button>
                                </span-->
                                <span class="input-group-append">
                                    <button class="btn btn-dark btn-icon" type="button" @click="emoji = !emoji">
                                        <i :class="{ 'imoji-box-open': emoji }" class="fas fa-smile img_toggle"></i>
                                    </button>
                                </span>
                                <span class="input-group-append">
                                    <button class="btn btn-dark btn-icon" type="submit"><i class="icon-paperplane"></i></button>
                                </span>
                            </div>
                    </div>
                    <div class="emoji_box" :class="{ active: emoji }">
                        <VEmojiPicker @select="selectEmoji" />
                    </div>
                </form>

                <span class="badge badge-yellow" v-if="typing">{{ to_user.first_name }} {{ __('is_typing') }}...</span>

                <div class="mt-2 bg-white timer-display" v-if="record_status">
                    <div class="timer-padding">
                        <span id="timer" class="timer"> {{ timing }} </span>
                    </div>
                    <div class="timer-padding">
                        <img :src="baseUrl + asset_type + '/chat/images/recording.gif'" alt=""
                            style="width: 140px; height: 35px">
                        <span class="text-muted">
                            {{ __('your_voice_is_recording') }}...
                        </span>
                    </div>
                    <div class="stop-button-padding">
                        <a href="#" @click="toggleRecording">
                            <img :src="baseUrl + asset_type + '/chat/images/recording-stop.png'" alt=""
                                style="height: 35px; width: 35px;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /basic layout -->

        <div id="chat_search" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="input-group">
                            <input type="text" class="form-control" :placeholder="__('keywords')" v-model="keywords" @keyup.enter.exact="search">
                            <span class="input-group-append">
                                <button @click="search()" class="btn btn-primary">{{ __('search') }}</button>
                            </span>
                            <span class="input-group-append">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">{{ __('close') }}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="imageView" class="modal fade" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ __('preview') }}</h5>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xl-12">
                                <img :src="imageSrc" alt="" class="w-100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="forward" class="modal fade" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ __('forward_message') }}</h5>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body" v-if="forward_conversation">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card mb-2">
                                    <div class="card-header">
                                        "{{ this.forward_conversation.message }}"<br>
                                        - {{ this.forward_conversation.from_user.first_name }}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input v-model="forward_addon_text" class="form-control form-control-sm" :placeholder="__('write_something_Optional')" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="card card-body p-0 border-0 shadow-none">
                            <ul class="media-list" v-for="(list_user, indexFor) in connected_users" :key="list_user.id">
                                <li class="media">
                                    <div class="mr-3">
                                        <a href="#">
                                            <img class="rounded-circle" width="40" height="40" v-if="list_user.avatar" :src="'/' + list_user.avatar" alt="">
                                            <img class="rounded-circle" width="40" height="40" v-else-if="list_user.avatar_url" :src="baseUrl + list_user.avatar_url" alt="">
                                            <img class="rounded-circle" width="40" height="40" v-else :src="baseUrl + 'global_assets/images/default_user_pic.png'" alt="">
                                        </a>
                                    </div>

                                    <div class="media-body">
                                        <div class="media-title font-weight-semibold">{{ list_user.first_name }}</div>
                                        <span class="text-muted">{{ list_user.last_message }}</span>
                                    </div>

                                    <div class="align-self-center ml-3">
                                        <a href="#" :id="'forwordClick' + indexFor" v-if="forward_conversation.forward_from" @click="forward(forward_conversation.forward_from, list_user, indexFor)"
                                            class="btn btn-primary btn-sm"><i lass="fas fa-share-alt mr-2"></i>{{ __('send') }}</a>
                                        <a href="#" :id="'forwordClick' + indexFor" v-else @click="forward(forward_conversation, list_user, indexFor)"
                                            class="btn btn-primary btn-sm"><i class="fas fa-share-alt mr-2"></i>{{ __('send') }}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { VEmojiPicker } from 'v-emoji-picker';
// import LinkPreview from '@ashwamegh/vue-link-preview'

export default {
    components: {
        VEmojiPicker,
        // LinkPreview
    },
    props: {
        send_message_url: {
            type: String,
            required: true
        },
        app_url: {
            type: String,
            required: true
        },
        files_url: {
            type: String,
            required: true
        },
        from_user: {
            type: Object,
            required: true
        },
        to_user: {
            type: Object,
            required: true
        },
        loaded_conversations: {
            required: true
        },
        connected_users: {
            type: Array,
            required: true
        },
        forward_message_url: {
            type: String,
            required: true
        },
        delete_message_url: {
            type: String,
            required: true
        },
        load_more_url: {
            type: String,
            required: true
        },
        can_file_upload: {
            default: true
        },
        asset_type: {
            default: ''
        }
    },
    data() {
        return {
            baseUrl: Laravel.baseUrl,
            typing: false,
            messages: [],
            newMessage: null,
            users: [],
            activeUser: false,
            typingTimer: false,
            file: null,
            emoji: false,
            record_status: false,
            recorder: '',
            gumStream: '',
            preview_url: null,
            file_name_preview: null,
            addPadding: false,
            conversations: '',
            //search
            keywords: '',
            search_result_bar: false,
            search_result_count: 0,
            search_result_ids: [],
            search_index: 0,
            //quote
            replying: false,
            replying_text: '',
            replying_to: null,
            //
            imageSrc: '',
            //forward
            forward_conversation: '',
            forward_addon_text: null,
            //audio secound
            seconds: 0,
            timing: '',
            timerId: '',
            timingIntervalId: '',
            //load More
            current_conversation_ids: new Array(),
            loadable: true,
        }
    },
    created() {
        Echo.private('single-chat.' + this.from_user.id)
            .listen('ChatEvent', (e) => {
                console.log('come')
                this.messagePush(e.message)
                this.reset();
                this.$forceUpdate()
            })
            .listenForWhisper('single-typing', (e) => {
                this.typing = true;
                setTimeout(() => {
                    this.typing = false
                }, 2500);
            });
    },

    mounted() {
        if (typeof this.loaded_conversations == "object") {
            this.conversations = Object.keys(this.loaded_conversations).map((key) => {
                return this.loaded_conversations[key]
            })
        }

        if (this.conversations.length < 20) {
            this.loadable = false;
        }

        $('#chat_search').on('shown.bs.modal', function () {
            $(this).find('input:first').focus();
        });
    },

    methods: {
        sendMessage() {
            this.emoji = false;
            let config = { headers: { 'Content-Type': 'multipart/form-data' } }
            let formData = new FormData();
            if (this.replying_to) {
                formData.append('reply', this.replying_to);
            }
            formData.append('file_attach', this.file);
            formData.append('message', this.newMessage);
            formData.append('from_id', this.from_user.id);
            formData.append('to_id', this.to_user.id);

            if (this.file == null && this.newMessage == null) {
                return false;
            }

            axios.post(this.send_message_url, formData, config).then((response) => {
                if (response.data.empty) {
                    return 0;
                }
                this.replying = false;
                this.messagePush(response.data.message)
                this.reset();
                this.cleanReply();
                this.$forceUpdate()
                this.closePreview()
            }).catch((error) => {
                console.log(error);
            });
            this.newMessage = '';
            //$("#inputbox").css('height', '40px');

        },
        sendTypingEvent() {
            Echo.private('single-chat.' + this.to_user.id)
                .whisper('single-typing', {
                    user: this.from_id,
                    typing: true
                });
        },

        diffHuman(date) {
            let dateToCheck = moment(date).add(30, 'minutes');
            if (moment().isAfter(dateToCheck)) {
                if (moment(date).isSame(new Date(), "day")) {
                    return moment(date).format("HH:mm");
                } else {
                    if (ContainsAny(Laravel.mom_format, ["h:m","H:m","g:i"])) {
                        return moment(date).format(Laravel.mom_format);
                    } else {
                        return moment(date).format(Laravel.mom_format + " - HH:mm");
                    }
                }
            } else {
                return moment(date).fromNow();
            }
        },
        reset() {
            this.file = null;
            this.$refs.file.value = null;
        },
        onChangeFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        selectEmoji(emoji) {
            if (this.newMessage == null) {
                this.newMessage = '';
                this.newMessage += ' ' + emoji.data;
            } else {
                this.newMessage += ' ' + emoji.data;
            }
            $('#inputbox').focus()
        },

        toggleRecording() {
            this.emoji = false;
            if (this.recorder && this.recorder.state == "recording") {
                this.record_status = false;
                this.recorder.stop();
                this.gumStream.getAudioTracks()[0].stop();
            } else {
                navigator.mediaDevices.getUserMedia({
                    audio: true
                }).then((stream) => {
                    this.record_status = true;
                    this.timingIntervalId = window.setInterval(() => {
                        this.incrementSeconds()
                    }, 1000);

                    this.gumStream = stream;
                    this.recorder = new MediaRecorder(stream);
                    this.recorder.ondataavailable = (e) => {
                        clearInterval(this.timingIntervalId);
                        this.seconds = 0;
                        let config = { headers: { 'Content-Type': 'multipart/form-data' } }
                        let formData = new FormData();
                        formData.append('reply', this.replying_to);
                        formData.append('file_attach', e.data);
                        formData.append('message', this.newMessage);
                        formData.append('from_id', this.from_user.id);
                        formData.append('to_id', this.to_user.id);

                        axios.post(this.send_message_url, formData, config).then((response) => {
                            this.messagePush(response.data.message)
                            this.reset();
                            this.quote_close_preview();
                            this.$forceUpdate()
                        }).catch((error) => {
                            console.log(error);
                        });
                        this.newMessage = '';
                    };
                    this.recorder.start();
                });
            }
        },

        incrementSeconds() {
            this.seconds += 1;
            this.timing = this.timeConverter(this.seconds);
        },

        timeConverter(time) {
            // Hours, minutes and seconds
            let hrs = ~~(time / 3600);
            let mins = ~~((time % 3600) / 60);
            let secs = ~~time % 60;

            let ret = "";
            if (hrs > 0) {
                ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
            }
            ret += "" + mins + ":" + (secs < 10 ? "0" : "");
            ret += "" + secs;
            return ret;
        },

        messagePush(message) {
            if((message.to_id == this.to_user.id && message.from_id == this.from_user.id) || (message.to_id == this.from_user.id && message.from_id == this.to_user.id)){
                this.conversations.push({
                    from_id: message.from_id,
                    to_id: message.to_id,
                    id: message.id,
                    message: message.message,
                    message_type: message.message_type,
                    file_name: message.file_name,
                    original_file_name: message.original_file_name,
                    reply: message.reply,
                    forward_from: message.forward_from,
                    deleted_by_to: '0'
                });
            }
        },

        deleteMessage(c) {
            this.cleanReply();
            let formData = new FormData();
            formData.append('conversation_id', c.id);
            formData.append('user_id', this.from_user.id);

            axios.post(this.delete_message_url, formData,).then((response) => {
                if (response.data.success) {
                    const index = this.conversations.indexOf(c);
                    if (index > -1) {
                        this.conversations.splice(index, 1);
                    }
                    new Noty({
                        text: "Message deleted!",
                        type: 'success'
                    }).show();
                    this.$forceUpdate()
                } else {
                    new Noty({
                        text: "Oops! something went wrong!",
                        type: 'error'
                    }).show();
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        onFileChange(e) {
            const file = e.target.files[0];
            this.addPadding = false;
            $('#inputbox').focus();
            if (['image/jpg', 'image/png', 'image/jpeg', 'image/JPG', 'image/PNG', 'image/JPEG'].includes(file['type'])) {
                return this.preview_url = URL.createObjectURL(file);
            }
            return this.file_name_preview = file.name
        },
        closePreview() {
            this.preview_url = null
            this.file_name_preview = null
            this.reset();
            this.addPadding = false;
        },
        handleClick(preview) {
            console.log('click', preview.domain, preview.title, preview.description, preview.img)
        },
        url_maker(text) {
            //text = JSON.stringify(text)
            let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
            let replaceable_text = text.replace(urlRegex, function (url, b, c) {
                let url2 = (c == 'www.') ? 'http://' + url : url;
                return '<u><a href="' + url2 + '" target="_blank" class="text-body">' + ' ' + url + '</a></u>';
            })
            return replaceable_text
        },
        linkable(text) {
            text = JSON.stringify(text)
            let match = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.exec(text);
            if (match) {
                return true;
            } else {
                return false
            }
        },

        search() {
            this.search_index = 0;
            this.search_result_ids = [];
            this.search_index = 0;
            this.cleanReply();

            $('#chat_search').modal('hide');
            this.search_result_bar = true;
            let elements = document.getElementsByClassName('textmsg'); // get the elements

            for (let i = 0; i < elements.length; i++) {
                if (elements[i].innerHTML.indexOf(this.keywords) > -1) {
                    this.search_result_count += 1;
                    this.search_result_ids.push(elements[i].id)
                }
            }

            for (let k = 0; k < elements.length; k++) {
                if (elements[k].innerHTML.indexOf(this.keywords) > -1) {
                    let targetElement = document.getElementById(elements[k].id)
                    this.scrollIfNeeded(targetElement, document.getElementById('chat_container'));
                    $('#' + elements[k].id).css('background-color', 'rgb(137 203 255)');
                    break;
                }
            }
        },

        scroll_to_search() {
            if (this.search_index + 1 < this.search_result_ids.length) {
                this.search_index = this.search_index + 1;
                let targetElement = document.getElementById(this.search_result_ids[this.search_index]);
                this.scrollIfNeeded(targetElement, document.getElementById('chat_container'));
                $('#' + this.search_result_ids[this.search_index]).css('background-color', 'rgb(239 83 80)');
            }
        },

        scroll_to_search_up() {

            if (this.search_index >= 1) {
                this.search_index -= 1;
                let targetElement = document.getElementById(this.search_result_ids[this.search_index]);
                this.scrollIfNeeded(targetElement, document.getElementById('chat_container'));
                $('#' + this.search_result_ids[this.search_index]).css('background-color', 'rgb(44 186 204)');
            }
        },

        scrollIfNeeded(element, container) {
            if (element.offsetTop < container.scrollTop) {
                container.scrollTop = element.offsetTop - 70;
            } else {
                const offsetBottom = element.offsetTop + element.offsetHeight;
                const scrollBottom = container.scrollTop + container.offsetHeight;
                if (offsetBottom > scrollBottom) {
                    container.scrollTop = offsetBottom - container.offsetHeight;
                }
            }
        },

        close_search_bar() {
            let elements = document.getElementsByClassName('textmsg'); // get the elements

            for (let i = 0; i < elements.length; i++) {
                if (elements[i].innerHTML.indexOf(this.keywords) > -1) {
                    $('#' + elements[i].id).css('background-color', 'unset');

                }
            }
            this.keywords = '';
            this.search_result_bar = false;
            this.search_result_count = 0;
            this.search_result_ids = [];
            this.search_index = 0;
        },

        //quote

        reply(c) {
            this.replying = true;
            this.replying_text = c.message
            this.replying_to = c.id
            $('html, body').animate({ scrollTop: $('#inputbox').offset().top }, 'slow');

        },

        cleanReply() {
            this.replying = false;
            this.replying_text = ''
            this.replying_to = null
        },

        forward(c, u, id) {
            $('#forwordClick' + id).text(' Sent');
            $('#forwordClick' + id).attr('class', 'btn btn-outline-success btn-sm');
            this.cleanReply();
            c.to_id = u.id;
            c.from_id = this.from_user.id;
            c.forward = c.id;
            if (this.forward_addon_text) {
                c.message = this.forward_addon_text;
            } else {
                c.message = null;
            }
            axios.post(this.forward_message_url, c).then((response) => {
                // this.checkNewMessage()
                // this.reset();
                // this.$forceUpdate()
            }).catch((error) => {
                console.log(error);
            });
        },

        forwardModalOpen(c) {
            $('#forward').modal('show');
            this.forward_conversation = c;
        },

        quote_close_preview() {
            this.replying = false;
        },

        imageViewLargeScreen(url) {
            this.imageSrc = url;
            $('#imageView').modal('show');
        },

        loadMore() {
            this.current_conversation_ids = [];
            for (let c in this.conversations) {
                this.current_conversation_ids.push(this.conversations[c].id)
            }
            let formData = new FormData();
            formData.append('ids', JSON.stringify(this.current_conversation_ids));
            formData.append('user_id', this.to_user.id);
            axios.post(this.load_more_url, formData).then((response) => {
                if (response.data.success) {
                    if (response.data.conversations) {
                        //console.log(response.data.conversations)
                        for (let index in response.data.conversations) {
                            this.conversations.unshift(response.data.conversations[index])
                        }
                    } else {
                        this.loadable = false;
                    }
                } else {
                    new Noty({
                        text: "Oops! something went wrong!",
                        type: 'error'
                    }).show();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>
.pt-100px {
    padding-top: 100px;
}

.auto_height {
    max-height: 135px !important;
}

.link-preview-section {
    background: white !important;
}

.search_indicator {
    background: #ececec;
    padding: 10px;
    border-radius: 10px;
}

/*quote*/
.replying-box {
    background: #dde3f1;
    border-radius: 15px;
}

.mb-2-3 {
    margin-bottom: 2.3rem !important;
}

.close-quote-preview {
    float: right;
    margin-top: -57px;
}

.cursor-pointer {
    cursor: pointer;
}

.audio-padding {
    padding: 10px 5px 5px 5px;
}

.border-radius-25 {
    border-radius: 25px;
}

.sender_reply_p {
    padding: 10px 20px 5px 20px !important;
    background: #beacde !important;
    border-radius: 25px 0 0 0 !important;
}

.reply_p {
    padding: 10px 20px 5px 20px !important;
    background: #b9c4f3 !important;
    border-radius: 0 25px 0 0 !important;
}

.users-box {
    max-height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.timer-display {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    grid-column-gap: 5px;
}

.timer {
    font-size: 30px;
    color: red;
}

.timer-padding {
    padding: 13px 13px 5px 13px;
}

.stop-button-padding {
    padding-top: 10px;
}

.imoji-box-open {
    color: #2196f3;
}

.microphone-red {
    color: red;
}

.input_thumb img {
    border-radius: 20px;
}

.bg-less {
    background: transparent;
    color: #415094;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #7c32ff;
    border-radius: 32px;
    padding: 7px 24px 5px 23px;
    text-transform: uppercase;
    overflow: hidden;
    transition: .3s;
    height: 32px;
}

.forward-image {
    width: 60px;
}

.preview_imgs {
    position: absolute;
    top: -55px;
    z-index: 100;
    background: #fff;
    border-radius: 5px;
}

/* line 488, G:/laragon/www/CRM_frontend/Admin_crm/public/frontend/scss/_chat.scss */
.preview_imgs .close_preview {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 12px;
    background: #7C32FF;
    color: #fff;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: block !important;
    line-height: 15px;
    text-align: center;
}

/* line 501, G:/laragon/www/CRM_frontend/Admin_crm/public/frontend/scss/_chat.scss */
.preview_imgs .close_preview i {
    color: #fff;
    font-size: 7px;
    position: relative;
    top: -1px;
}

/* line 508, G:/laragon/www/CRM_frontend/Admin_crm/public/frontend/scss/_chat.scss */
.preview_imgs img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px solid #F5F7FB;
}

/* line 516, G:/laragon/www/CRM_frontend/Admin_crm/public/frontend/scss/_chat.scss */
.emoji_box {
    width: 280px;
    height: 380px;
    background: #FFFFFF;
    box-shadow: 0px 10px 15px rgba(235, 215, 241, 0.22);
    border-radius: 10px;
    position: absolute;
    right: 65px;
    bottom: 113px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

/* line 528, G:/laragon/www/CRM_frontend/Admin_crm/public/frontend/scss/_chat.scss */
.emoji_box.active {
    opacity: 1;
    visibility: visible;
}

.chat-item-opt{
    display: none;
}
.chat-item-base:hover > .chat-item-opt {
    display:unset;
}
</style>
