<template>
    <li class="nav-item dropdown">
        <a href="#" class="navbar-nav-link d-flex align-items-center" data-toggle="dropdown">
            <i v-if="status == 1" class="icon-circle2 text-success my-1 mr-1" ></i>
            <i v-else-if="status == 0" class="icon-circle2 text-dark my-2 mr-1" ></i>
            <i v-else-if="status == 3" class="icon-circle2 text-danger my-2 mr-1" ></i>
            <i v-else class="icon-circle2 text-warning my-2 mr-1" ></i>
            {{ __('chat_status') }}
        </a>
        <div class="dropdown-menu dropdown-menu-right">
            <a :href="domain+'chat/user/status/1?url='+current_path" class="dropdown-item font-weight-semibold text-success">
                <i v-if="status === 1" class="fa fa-check-circle"></i>
                {{__('chat_active')}}
            </a>
            <a :href="domain+'chat/user/status/0?url='+current_path" class="dropdown-item font-weight-semibold text-dark">
                <i v-if="status === 0" class="fa fa-check-circle"></i>
                {{__('chat_inactive')}}
            </a>
            <a :href="domain+'chat/user/status/2?url='+current_path" class="dropdown-item font-weight-semibold text-warning">
                <i v-if="status === 2" class="fa fa-check-circle"></i>
                {{__('chat_away')}}
            </a>
            <a :href="domain+'chat/user/status/3?url='+current_path" class="dropdown-item font-weight-semibold text-danger">
                <i v-if="status === 3" class="fa fa-check-circle"></i>
                {{__('chat_busy')}}
            </a>
        </div>
    </li>
</template>

<script>
export default {
    name: "StatusChangeNavbarComponent",

    props:{
        user: {
            required: true
        },
        status: {
            required: true
        },
    },
    mounted() {
        // this.current_path = window.location.pathname
    },

    data(){
        return {
            current_path: Laravel.current_path_without_domain,
            domain : Laravel.baseUrl
        }
    }

}
</script>
