<template>

    <li class="nav-item dropdown">
        <a href="#" class="navbar-nav-link navbar-nav-link-toggler" data-toggle="dropdown" id="chatNotifications">
            <i class="icon-bubbles2"></i>
            <span class="badge badge-pink badge-pill ml-auto ml-lg-0" v-if="count_unread">{{ count_unread }}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right dropdown-content wmin-lg-350">
            <div class="dropdown-content-header">
                <span class="font-weight-semibold">{{ __('chat_notifications') }}</span>
                <audio id="sound" :src="baseUrl+'modules/chat/audio/notification.mp3'" muted></audio>
            </div>
            <div class="dropdown-content-body dropdown-scrollable">
                <ul class="media-list" id="chatNotificationsMenu" v-for="unread in this.unreads" :key="unread.id">

                    <li class="media">
                        <div class="mr-3 position-relative" v-if="unread.data">
                            <img v-if="unread.data.user.avatar" :src="baseUrl+ unread.data.user.avatar" class="rounded-circle" width="40" height="40" alt="">
                            <img v-else-if="unread.data.user.avatar_url" :src="baseUrl+ unread.data.user.avatar_url" class="rounded-circle" width="40" height="40" alt="">
                            <img v-else :src="baseUrl +'global_assets/images/default_user_pic.png'" class="rounded-circle" width="40" height="40" alt="">
                        </div>
                        <div class="mr-3 position-relative" v-else>
                            <img v-if="unread.user.avatar" :src="baseUrl+ unread.user.avatar" class="rounded-circle" width="40" height="40" alt="">
                            <img v-else-if="unread.user.avatar_url" :src="baseUrl+ unread.user.avatar_url" class="rounded-circle" width="40" height="40" alt="">
                            <img v-else :src="baseUrl +'global_assets/images/default_user_pic.png'" class="rounded-circle" width="40" height="40" alt="">
                        </div>

                        <div class="media-body">
                            <div v-if="unread.type == 'Modules\\Chat\\Notifications\\InvitationNotification'">
                                <div class="media-title">
                                    <a v-if="unread.data" :href="unread.data.url+'/'+unread.id">
                                        <span class="font-weight-semibold">{{ unread.data.user.first_name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                    <a v-else :href="unread.url+'/'+unread.id">
                                        <span class="font-weight-semibold">{{ unread.user.first_name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                </div>
                                <span class="text-muted" v-if="unread.data">{{ unread.data.message}}</span>
                                <span class="text-muted" v-else>{{ unread.message}}</span>
                            </div>

                            <div v-else-if="unread.type == 'Modules\\Chat\\Notifications\\GroupCreationNotification'">
                                <div class="media-title">
                                    <a v-if="unread.data" :href="unread.data.url">
                                        <span class="font-weight-semibold">{{ unread.data.group.name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                    <a v-else :href="unread.url">
                                        <span class="font-weight-semibold">{{ unread.group.name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                </div>
                                <span class="text-muted">{{__('you_are_invited_in_new_group')}}!</span>
                            </div>

                            <div v-else-if="unread.type == 'Modules\\Chat\\Notifications\\GroupMessageNotification'">
                                <div class="media-title">
                                    <a v-if="unread.data" :href="unread.data.url">
                                        <span class="font-weight-semibold">{{ unread.data.group.name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                    <a v-else :href="unread.url">
                                        <span class="font-weight-semibold">{{ unread.group.name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                </div>
                                <span class="text-muted">{{__('new_message_in_this_group')}}!</span>
                            </div>
                            <div v-else>
                                <div class="media-title">
                                    <a v-if="unread.data" :href="redirect_url+'/'+unread.data.user.id+'/'+unread.id">
                                        <span class="font-weight-semibold">{{ unread.data.user.first_name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                    <a v-else :href="redirect_url+'/'+unread.user.id+'/'+unread.id">
                                        <span class="font-weight-semibold">{{ unread.user.first_name }}</span>
                                        <span class="text-muted float-right font-size-sm">{{ diffHuman(unread.created_at) }}</span>
                                    </a>
                                </div>
                                <span class="text-muted" v-if="unread.thread">{{ unread.thread.message}}</span>
                                <span class="text-muted" v-else>{{ unread.data.thread.message}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="dropdown-content-footer justify-content-center p-0" v-if="count_unread">
                <a :href="mark_all_as_read_url" class="btn btn-light btn-block border-0 rounded-top-0" data-popup="tooltip" :title="__('mark_all_as_read')" data-original-title="Load more">{{__('mark_all_as_read')}}</a>
            </div>
        </div>
    </li>

</template>

<script>

export default {
    props:{
        unreads: {
            type: Array,
            required: true
        },
        redirect_url: {
            type: String,
            required: true
        },
        user_id: {
            type: Number,
            required: true
        },
        asset_type:{
            default : ''
        },
        mark_all_as_read_url: {
          type: String,
          required: true
        },
    },
    data() {
        return {
            baseUrl: Laravel.baseUrl,
            count_unread : '',
            open_modal: false,
            demotext:''
        }
    },
    mounted() {
        this.count_unread = this.unreads.length
    },
    created() {
        Echo.private('App.Models.User.' + this.user_id)
            .notification((notification) => {
                this.unreads.push(notification)
                this.count_unread += 1;
                let sound = document.getElementById('sound')
                sound.pause();
                sound.currentTime = 0;
                sound.volume = 0.3;
                sound.play();
            });
    },

    methods: {
        diffHuman(date) {
            return moment(date).fromNow();
        }
    }
}
</script>
