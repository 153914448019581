window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.moment = require('moment');
    window.moments = require('moment');
    require('moment-locale-fr');
    var momentDurationFormatSetup = require("moment-duration-format");
    momentDurationFormatSetup(moments);
    require('bootstrap');
    //window.Pusher = require('pusher-js');
} catch (e) {}

window.moments.locale(_locale);
window.moment.locale(_locale);
//var cTime = moments().format('Do MMMM, hh:mm');
//console.log(cTime);
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let csrf_token = document.head.querySelector('meta[name="csrf-token"]');
if (csrf_token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

if (window.Laravel.userId != undefined) {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        forceTLS: true,
        authEndpoint: process.env.MIX_PUSHER_AUTH_URL,
    });
}
